<template>
  <div id="contact">

    <transition name="fadeSmooth">
        <div id="message" v-bind:class="{messageErreur : !messageOK}"   v-if="message">{{messageTxt}}</div>
    </transition>

    <div id="container-contact" class="containerAffichage">

        <div class="title">
            <h3 v-if="!sitePRO">Une question ? Un message ?</h3>
        </div>

        <div class="consigne">Tous les champs suivis d'un * sont obligatoires</div>

        <div class="edition">

            <form id="contactOEForm">

                <v-text-field
                    label="Nom *"
                    v-model="form.nom"
                    required
                    :error-messages="error.nom"
                    ref="nom"
                    title="Saisissez votre nom"
                ></v-text-field>
                <v-text-field
                    label="Prénom"
                    v-model="form.prenom"
                    ref="prenom"
                    title="Saisissez votre prénom"
                ></v-text-field>
                <v-text-field
                    label="Adresse courriel *"
                    v-model="form.adresseMail"
                    required
                    :error-messages="error.adresseMail"
                    ref="adresseMail"
                    title="Saisissez votre adresse courriel"
                ></v-text-field>

                    <v-select
                        dense
                        attach
                        required
                        ref="statut"
                        v-model="form.statut"
                        item-text="libelle"
                        item-value="libelle"
                        :items="lesStatuts"
                        label="Situation *"
                        :error-messages="error.statut"
                        :return-object="false"
                        @change="onChangeStatut()"
                    ></v-select>
                    <div id="autreStatut"  v-if="autre">
                        <v-text-field
                            label="Précisez *"
                            v-model="form.autreStatut"
                            ref="autreStatut"
                            title="Précisez votre statut"
                            :error-messages="error.autreStatut"
                        ></v-text-field>
                    </div>
                    <v-text-field
                        label="Objet *"
                        v-model="form.sujet"
                        required
                        :error-messages="error.sujet"
                        ref="sujet"
                        title="Indiquez l'objet de votre demande"
                    ></v-text-field>
                    <div class="messageConteneur">
                      <v-textarea
                          label="Message *"
                          v-model="form.message"
                          required
                          ref="message"
                          title="Précisez votre demande en détaillant au maximum votre situation"
                          maxlength="280"
                      ></v-textarea>
                      <span class="messageNbCaracteres">{{messageNbCaracteres}}</span>
                    </div>

                    <v-checkbox
                      v-if="0"
                        v-model="form.utilisateurCopie"
                        on-icon="fa fa-check-square"
                        off-icon="fa fa-square"
                        label="Tester apiagenda.lorfolio.fr"
                        @change="testAgenda"
                    ></v-checkbox>

                    <div v-if="this.ready" class="zoneAntispam">
                        <span class="labelAntispam">Validation anti-spam *</span>
                        <span v-if="this.ready" class="consigne">{{laVerif}}</span>
                        <v-text-field v-if="this.ready"
                            :label="laSousVerif"
                            v-model="resultat"
                            required
                            :error-messages="error.resultat"
                            ref="resultat"
                        ></v-text-field>
                    </div>
            </form>
        </div>

        <!-- les boutons peuvent être mis dans un composant pour être générique -->
        <div class="boutonsValidation">
            <v-btn class="a-btn prioritaire registerBD" type="button" @click="saveForm">Envoyer</v-btn>
        </div>

    </div>

  </div>
</template>


<script>

import { addeoPost } from "../js/datas.js"

import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, email, minValue, maxValue } from 'vuelidate/lib/validators'



export default {
    name: 'Contact',
    data() {
        return {
            //toto:false,
                messageTxt:"",
                messageOK:true,
                message:false,
                myMessageTimeout:null,
            form:  {
                origine:"orientest.fr",
                nom:"",
                prenom:"",
                adresseMail:"",
                statut:"",
                autreStatut:"",
                sujet:"",
                message:"",
                utilisateurCopie:true,
            },
            resultat:"",
            old: {

            },
            error: {
                nom: '',
                sujet: '',
                adresseMail: '',
                statut:'',
                autreStatut:'',
                resultat: ''
            },
            ready:false,
            antiSpam1:89,
            antiSpam2:72,
            myTimeout:null,
            myTimeoutStatutFocus:null
        }
    },
    components: {
    },
    props: {
        sitePro: {
            type: Boolean
        },
    },
    created() {
        this.myTimeout = setTimeout(this.preparerAntispam, 1500);

    },
    mounted() {
        //this.myTimeout = setTimeout(this.toto=true, 2500);
    },

    validations() {
        return {
            form: {
                nom: { required, maxLength: maxLength(65) },
                sujet: { required, maxLength: maxLength(65) },
                adresseMail: { required, email },
            }
        }
    },
    computed: {
        autre() {
            if (this.form.statut == "Autre") {
                return(true);
            }
            return(false);
        },
        lesStatuts() {
            var  tabStatuts = [{libelle:"Demandeur d’emploi", code:"DE"},
                {libelle:"Salarié", code:"SA"},
                {libelle:"Public jeune", code:"PJ"},
                {libelle:"Professionnel de l’orientation", code:"PF"},
                {libelle:"Professionnel de la formation", code:"PO"},
                {libelle:"Autre", code:"A"}];
            return(tabStatuts);
        },
        sitePRO() {
            return(this.$parent.sitePRO);
        },
        markOrientest() {
            return(this.$router.options.markOrientest);
        },
        lesParams() {
            return(this.$route.params.pere);
        },
        laSousVerif() {
            return(this.antiSpam1+" + "+ this.antiSpam2+" =");
        },
        laVerif() {
            return("Donnez le résultat de la somme "+this.antiSpam1+" + "+ this.antiSpam2);
        },
        markOrientest() {
            return(this.$router.options.markOrientest);
        },
        messageNbCaracteres() {
            return this.form.message.length > 0 ? this.form.message.length + '/280' : '';
        }
    },
    methods: {
        reinitForm()  {
                this.form.nom="";
                this.form.prenom="";
                this.form.adresseMail="";
                this.form.statut="";
                this.form.autreStatut="";
                this.form.sujet="";
                this.form.message="";
        },
        onChangeStatut() {
            if (this.autre) {
                this.myTimeoutStatutFocus = setTimeout(this.preparerStatutFocus, 500);
            }
        },
        preparerStatutFocus() {
                if (this.myTimeoutStatutFocus) {
                    clearTimeout(this.myTimeoutStatutFocus);
                }
            this.$refs.autreStatut.focus()
        },
        finMessage() {
            if (this.myMessageTimeout) {
                clearTimeout(this.myMessageTimeout);
            }
            this.message = false;
        },
        getRandomInt(max) {
          return Math.floor(Math.random() * Math.floor(max))+1;
        },
        preparerAntispam() {
                if (this.myTimeout) {
                    clearTimeout(this.myTimeout);
                }
            this.antiSpam1= this.getRandomInt(50);
            this.antiSpam2= this.getRandomInt(30);
            this.ready = true;
        },

        testAgenda() {
            return addeoPost.posterDemandeAgenda("[{}]")
                 .then(response => {
                    var titit = response;
                    return(titit);
                })
            },

        // enregistrement du formulaire
        async saveForm() {

            var thisObj = this;
            // on reinit à vide les messages d'erreur
            thisObj.reinitErrorMessages();

            // si pb de validation, on retourne false et on définit les messages d'erreur
            const retourStatut = this.statutIsFalse();
            const retourResultat = this.resultatIsFalse();
            if (retourStatut || retourResultat || thisObj.$v.$invalid) {
                // maj erreurs
                thisObj.sujetErrors();
                thisObj.adresseMailErrors();
                thisObj.nomErrors(); // dans l'ordre inverse pour le focus
                return false
            }


            // on sauvegarde (distant et local)
            thisObj.old = JSON.parse(JSON.stringify(thisObj.form)) // l'éta "en cours" du formulaire devient l'ancien (pour la restauration)
            var entityToSave = JSON.parse(JSON.stringify(thisObj.form))
            console.log("saveForm OK")

            //return await addeoPost.posterContact(entityToSave) // on sauvegarde notre propriété (en DB locale et SI POSSIBLE en ligne)
            // dès feu vert de Greg
            return await addeoPost.posterContactLORFOLIO(entityToSave) // on sauvegarde notre propriété (en DB locale et SI POSSIBLE en ligne)
             .then(response => {
                var message = "";
                var ok = false;
                //var ok = true;
                if (this.markOrientest) {
                    if (!response || !response.ok) {
                        message = "Votre demande de contact n'a pu être transmise : veuillez envoyer un mail à contact@orientest.fr";
                    }
                    else {
                        message = "Votre demande de contact a bien été transmise. L'équipe Orient'Est vous remercie et reviendra vers vous dans les meilleurs délais.";
                        ok = true;
                    }
                }
                else {
                    if (!response || !response.ok) {
                        message = "Votre demande de contact n'a pu être transmise : veuillez envoyer un mail à contact@lorfolio.fr";
                    }
                    else {
                        message = "Votre demande de contact a bien été transmise. L'équipe Lorfolio vous remercie et reviendra vers vous dans les meilleurs délais.";
                        ok = true;
                    }
                }

                // on prépare le message passé à la prochaine page
//                message = "La saisie de la propriété "+this.form.identifiant+ " a bien été enregistrée.";

                // on va au bon endroit avec les bonnes valeurs
                this.terminerEdition(message, ok);
            })

        },


        // on va au bon endroit avec les bonnes valeurs
        terminerEdition(message, ok) {
            if (message) {
                this.message=true;
                this.messageTxt=message;
                this.messageOK=ok;
                this.myMessageTimeout = setTimeout(this.finMessage, 5000);
            }

            if (ok) {
                this.reinitForm();
            }
        },

        // validation du formulaire
        reinitErrorMessages() {
            this.error.nom = ''
            this.error.adresseMail = ''
            this.error.sujet = ''
            this.error.resultat = ''
            this.error.statut = ''
            this.error.autreStatut = ''
        },

        nomErrors () {
            const errors = [];
            if (this.$v.form.nom.$invalid) {
                this.error.nom = "Vous devez obligatoirement saisir votre nom";
                this.$refs.nom.focus();
                return false;
            }
            else {
                this.error.nom = "";
            }
            return true;
        },
        adresseMailErrors () {
            const errors = [];
            if (!this.$v.form.adresseMail.email || this.$v.form.adresseMail.$invalid) {
                this.error.adresseMail = "Vous devez saisir une adresse courriel valide";
                this.$refs.adresseMail.focus();
                return false;
            }
            else {
                this.error.adresseMail = "";
            }
            return true;
        },
        sujetErrors () {
            const errors = [];
            if (this.$v.form.sujet.$invalid) {
                this.error.sujet = "Vous devez indiquer la raison pour laquelle vous nous contactez";
                this.$refs.sujet.focus();
                return false;
            }
            else {
                this.error.sujet = "";
            }
            return true;
        },
        statutIsFalse () {
            if (this.form.statut == "") {
                this.error.statut = "Vous devez indiquer votre statut";
                this.$refs.statut.focus();
                return true;
            }
            else {
                if (this.autre && this.form.autreStatut == "") {
                    this.error.autreStatut = "Vous devez préciser quel est votre statut";
                    this.$refs.autreStatut.focus();
                    return true;
                }
                this.error.statut = "";
                this.error.autreStatut = "";
            }
            return false;
        },
        resultatIsFalse () {
            if (this.resultat != (this.antiSpam1 + this.antiSpam2)) {
                if (this.resultat == "") {
                    this.error.resultat = "Vous devez indiquer le résultat du calcul";
                }
                else {
                    this.error.resultat = "Vous devez indiquer le résultat correct du calcul";
                }
                this.$refs.resultat.focus();
                return true;
            }
            else {
                this.error.resultat = "";
            }
            return false;
        },
    }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
    html body {
      min-width: unset;
    }
    /* pour corriger le placement du v-select */
    html body #app div {
        position: static;
    }

    .addeoSmall {
        #contact {
            #container-contact {
                div.edition {
                    width: 95%;
                    max-width: 1000px;
                }
                .boutonsValidation {
                    width: 95%;
                    margin: 0 auto;
                    button {
                        width:75%;
                        max-width: 75px;
                    }
                }
            }
        }
    }

    #contact {

        #container-contact {
            text-align: center;

            h3 {
                color:#006EAB;
                font-family: Dosis, sans-serif;
                font-size: 1.4rem;
            }

            div.consigne {
                margin:1em 0;
                font-size: 0.9em;
            }
            .consigne {
                font-style: italic;
                display: inline-block;
            }
            .consigneCalcul {
                margin:0.3em 0;
                color:#356A73;
            }

            div.edition {
                width: 100%;
                max-width: 405px;
                margin: 1em auto;
                text-align: left;
            }

            .v-input {

                .v-input__slot, .v-text-field__slot {
                    background-color: #F8F8FC;
                    border-radius: 5px 5px 0 0;

                    input, textarea {
                        margin:0.4em 2em;
                        &:focus, :focus {
                            outline:transparent solid 0px !important;
                        }
                    }
                    label {
                        left:1em !important;
                        right:auto !important;
                    }
                }
                .v-input__slot:before {
                    border-color: #006EAB;
                }
                .v-messages__message {
                    color:darkorange;
                }

            }
        }
    }



html {
    body {
        #app.addeoSmall, #app.addeoMedium, #app.addeoLarge  {
            #message {

                width: 96%;
                padding:1em 2%;
                min-height: 3em;
                height: auto;

                &.messageErreur {
                    background-color: firebrick !important;
                }
            }
        }
    }
}



/**/
    @font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v67/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
}
.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}
    /**/


    /* reprise matérial design : 2022-02 */

    #blocVersion {
        display:none;
    }

    #contact {

        #container-contact {
            font-family: Roboto, Arial, sans-serif;

            text-align: center;
            width: 100%;
            max-width:410px;
            margin:auto;

            #autreStatut {
                margin-top: 0.3em;
            }

            div.consigne {
                margin:1em 0;
                font-size: 0.9em;
            }
            .consigne {
                font-style: italic;
                display: inline-block;
            }
            .zoneAntispam {
                margin-top: 0.6em;
                padding-bottom: 0.4em;
                display: inline-block;
                width: 100%;
                .labelAntispam {
                    display: block;
                    font-weight:500;
                }
                .consigne {
                    display: block;
                    margin:0.5em 0;
                }
                .v-input {
                    margin: 0;
                }
            }

            div.edition {
                width: 100%;
                max-width: 600px;
                /*margin: 1.0em auto 0.8em;*/
                margin: 10px auto 10px;
                text-align: left;
            }

            .v-input {
                display:inline-block;
                margin: 0.6em 0 0;
                padding:0;
                /*
                width: 33%;
                max-width: 280px;
                */
                width: 100%;

                .v-input__slot, .v-text-field__slot {
                    background-color:#EBEBEB;
                    color: #2C3E50;

                    border-radius: 4px 4px 0 0;
                    width:100%;
                    margin-bottom:0;
                    /*margin-bottom: 1.4em;*/

                    input, textarea, div.v-select__selections {
                        font-size:100%;
                        margin:0 5%;
                        padding:1.0em 0 0.1em;
                        width:90%;
                        &:focus, :focus {
                            outline:transparent solid 0px !important;
                        }
                    }
                    textarea {
                        margin-right: 0;
                    }

                    div.v-select__selections {
                        input {
                            padding-top:0;
                        }
                    }

                    label {
                        left:1em !important;
                        top:0.6em !important;
                        right:auto !important;
                        /*color:#356A73;*/
                        color:#006EAB;
                        width: 100%;
                    }
                    .v-label--active {
                        transform: translateY(-8px) scale(.6);
                        left: -17% !important;
                        right:unset !important;
                    }
                }

                /* marges latérales réduites */
                .v-input .v-input__slot div.v-select__selections, .v-input .v-input__slot input, .v-input .v-input__slot textarea, .v-input .v-text-field__slot div.v-select__selections, .v-input .v-text-field__slot input, .v-input .v-text-field__slot textarea {
                    margin-left:0;
                    margin-right:0;
                    padding-left:3%;
                    padding-right:3;
                }
                .v-list{
                    padding:0;
                }
                /* Icone de flèche dans le select ouvert */
                .v-list-item {
                    position:relative;
                }
                .v-list-item:first-child .v-list-item__content:before{
                    content:'';
                    position:absolute;
                    right:0;
                    top:40%;
                    width:0;
                    height:0;
                    border-color:transparent transparent #006eab;
                    border-width:0 6px 6px;
                    border-style:solid;
                }
                .v-input.v-select:not(.v-select--is-menu-active) .v-input__append-inner:before{
                    content:'';
                    position:absolute;
                    right:1em;
                    top:40%;
                    width:0;
                    height:0;
                    border-color:#006eab transparent transparent;
                    border-width:6px 6px 0;
                    border-style:solid;
                }

                &.v-select:not(.v-select--is-menu-active) {
                    .v-input__append-inner {
                        margin: 6px 4px 0 0;
                        background: no-repeat center center transparent url("~@/assets/img/oe/down.png");
                        background-size: 60%;
                    }
                }
                .v-list-item__content {
                    color: #006EAB;
                    position:relative;
                }

                .v-list-item--link:before {
                    background-color:#006EAB;
                }


                .v-text-field__details {
                    height: 9px;
                    left: 0;
                    margin:0.1em 0 0.2em;
                    /*
                    position: absolute;
                    top: -1.4em;
                    */
                    .v-messages {
                        min-height: 0px;
                        display:inline;
                        .v-messages__message {
                            /*color:#b00020;*/
                            color: #eb6b14;
                            font-size: 85%;
                            vertical-align: top;
                        }
                    }
                }

                &.error--text {
                    label {
                        /*color: #b00020;*/
                        color: #eb6b14;
                    }
                    .v-input__slot:before {
                        /*border-color: #b00020;*/
                        color: #eb6b14;
                    }
                }
            }
            .v-input:not(.v-input--is-label-active) {
                .v-input__slot {
                    .v-text-field__slot {
                        input {
                            /*
                            color:transparent;
                            */
                        }
                    }
                }
            }
            .boutonsValidation {
                font-family: Montserrat, Roboto, Arial, sans-serif;
                text-align: right;
                button {
                    font-size: 0.75em;
                    letter-spacing: 0.03em;
                    min-width: auto;
                    padding: 6px;
                    margin-top:0;
                    height:30px;
                    text-transform: none;

                    color:white;
                    background-color: #006eab;
                    border-radius: 0;
                    box-shadow: none;
                    border: 1px solid #006eab;
                    .v-btn__content {
                        background-color: transparent;
                    }
                    &:hover {
                        background-color: #fff;
                        color: #006eab;
                        &:before  {
                            opacity: 1;
                            background-color: transparent;
                            transition: none;
                        }
                    }
                }
            }
            .messageConteneur {
                position:relative;
            }

            .messageNbCaracteres {
                position:absolute;
                bottom:3px;
                right:0px;
                font-size:0.62em;
                font-family:Verdana, sans-serif;
            }


            .v-menu__content {
                position:absolute;
            }
        }


    }


</style>
